import type { SquareButtonProps } from '../Types'
import { useMemo } from 'react'
import classNames from 'classnames'
import isPresent from '@vayapin/utils/isPresent'

export interface TitleProps {
  title: SquareButtonProps['title'];
  disabled: SquareButtonProps['disabled'];
  theme: SquareButtonProps['theme'];
}

const DEFAULT_CLASSES = `
  relative overflow-hidden text-xs text-center line-clamp-2
`

const NOT_DISABLED_CLASSES = `
  text-neutral-800 group-hover/vp-square-btn:text-primary-400
`

const DISABLED_CLASSES = `
  text-neutral-400 group-hover/vp-square-btn:text-neutral-400
`

const SECONDARY_NOT_DISABLED_CLASSES = `
  text-neutral-800 group-hover/vp-square-btn:text-secondary-600
`

function Title({ title, disabled, theme = 'default' }: Readonly<TitleProps>) {
  const hasTitle = useMemo(() => isPresent(title), [title])

  const labelClasses = useMemo(() => classNames({
    [DEFAULT_CLASSES]: true,
    [DISABLED_CLASSES]: disabled,
    [NOT_DISABLED_CLASSES]: !disabled && (theme === 'default' || theme === 'primary'),
    [SECONDARY_NOT_DISABLED_CLASSES]: !disabled && theme === 'secondary',
  }), [disabled, theme])

  return hasTitle ? (
    <span className={labelClasses}>
      {title}
    </span>
  ) : null
}

export default Title
